import React from 'react'

import SiteJobs from '../../../components/SiteJobs'

import SEO from '../../../components/seo'

import Truck from './IMG_7661.jpg'

const schemaOrg = `[
  {
      "@context": "http://schema.org",
      "@type": "JobPosting",
      "description": "Vous désirez offrir vos services? Nous sommes toujours à la recherche de candidat pour joindre notre équipe.",
      "employmentType": "Temps plein",
      "industry": "Services Irrigation",
      "jobLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Montréal",
          "addressLocality": "Laval",
          "addressRegion": "QC"
        }
      },
      "workHours": "40 heures par semaine"
    }
]`

class contact extends React.Component {

  render() {

	  const seoData = {
      title: 'Une carrière chez Irriglobe',
      description: 'Vous désirez offrir vos services? Nous sommes toujours à la recherche de candidat pour joindre notre équipe.',
      coverImage: Truck,
      path: this.props.location.pathname,
      schemaOrg
    }

    return (
      <div>
        <SEO seoData={seoData} />
        <SiteJobs {...this.props} />
      </div>
    )
  }
}

export default contact
