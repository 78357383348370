import React from 'react'
import './style.scss'

import {
  Form,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row,
} from 'reactstrap'

import NotificationAlert from 'react-notification-alert'

import { FormInputs, Button } from '../../components'

import ReactLoading from 'react-loading';

import Truck from '../../assets/img/IMG_7661.jpg'

import SEO from '../../components/seo'

class SiteJobs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }

  handleSubmit = (event) => {
    try {
      event.preventDefault();
      var message = ''
      var form = document.getElementById('contactForm');
      const data = new FormData(form);
      if(document.getElementById("honeypot").value) {
        return;
      }else if(data.get('email')===''){
        this.showNotification('Veuiller remplir le champ courriel.')
        return;
      }
      this.setState({sending: true})
      fetch('/api/career', {
        method: 'POST',
        body:  JSON.stringify({
          name: data.get('name'),
          email: data.get('email'),
          phone: data.get('phone'),
          message: data.get('message'),
          cv: data.get('cv'),
        })
      }).then((data)=>{
        this.setState({sending: false})
        if(data.ok === true && data.status===200){
          message = "Votre message a bien été envoyé."
        } else {
          message = 'Il y a eu un problème. Veuillez communiquer avec nous par téléphone.'
        }
        this.showNotification(message)
      }).catch((error)=>{
        this.setState({sending: false})
        message = 'Il y a eu un problème. Veuillez communiquer avec nous par téléphone.'
        this.showNotification(message)
      });
    } catch(e){

    }
  }

  showNotification = (message) => {
      var options = {}
      options = {
        place: 'tr',
        message: message,
        type: 'info',
        icon: 'now-ui-icons ui-1_bell-53',
        autoDismiss: 7,
      }
      this.refs.notificationAlert.notificationAlert(options)
  }

  render() {

    const seoData = {
      title: "Carrière",
      description: "Envoyez-nous votre candidature",
      coverImage: Truck,
      path: '/compagnie/emploi',
      schemaOrg: ''
    }

    return (
      <Container>
        <SEO seoData={seoData} />
        <NotificationAlert ref="notificationAlert" />
        <Row className="justify-content-center">
          <Col lg={5} md={8} xs={12}>
            <h1>Une carrière chez Irriglobe</h1>
            <Card>
              <CardHeader>
                <CardTitle>Envoyez-nous votre candidature!</CardTitle>
              </CardHeader>
              <CardBody>
                <Form id="contactForm" name="contact" method="post" data-netlify="true" data-netlify-honeypot="honeypot" onSubmit={this.handleSubmit}>
                  <div style={{display:'none'}}>
                     <label htmlFor="honeypot">Keep this field blank</label>
                     <input type="text" name="honeypot" id="honeypot" />
                  </div>
                  <FormInputs
                    ncols={['col-12', 'col-12', 'col-12', 'col-12', 'col-12']}
                    proprieties={[
                      {
                        label: 'Nom',
                        inputProps: {
                          type: 'text',
                          name: 'name'
                        },
                      },
                      {
                        label: 'Courriel',
                        inputProps: {
                          type: 'email',
                          name: 'email'
                        },
                      },
                      {
                        label: 'Téléphone',
                        inputProps: {
                          type: 'phone',
                          name: 'phone'
                        },
                      },
                      {
                        label: 'Lettre de présentation',
                        inputProps: {
                          type: 'textarea',
                          name: 'message'
                        },
                      },
                      {
                        label: 'CV',
                        inputProps: {
                          type: 'textarea',
                          name: 'cv'
                        },
                      },
                    ]}
                  />
                    {
                      (this.state.sending)?
                        <Row className="justify-content-center">
                        <Col lg={3}>
                        <ReactLoading type='bars' color='#1992d1' height={50} width={50} />
                        </Col>
                        </Row>:
                        <Row className="justify-content-center">
                        <Col lg={4}>
                        <Button color="primary" size="lg" round type="submit">
                          Envoyer
                        </Button>
                        </Col>
                        </Row>
                    }
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg={5} md={8} xs={12}>
            <img src={Truck} alt="Camion Irriglobe" />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SiteJobs
